import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { ShopifyNode } from "../../types/common";

const Item = ({ pageContext }: ShopifyNode) => {
  const { product } = pageContext;
  const { title, id, tags, variants, onlineStoreUrl } = product;
  // let product = node.data.allShopifyProduct.edges;

  return (
    <div className="container h-full bg-white shadow-sm rounded-md p-6 m-2 overflow-hidden">
      <h2 className="text-2xl text-black font-bold leading-snug">{title}</h2>{" "}
      <br />
      Product ID: {id} <br />
      Tags: {tags} <br />
      Link:{" "}
      <a href={onlineStoreUrl} className="underline">
        {onlineStoreUrl}
      </a>{" "}
      <br />
      <br />
      {variants?.map((tier, index) => (
        <div
          key={title + index}
          className="container inline-block my-2 px-4 py-2 border rounded-lg border-primary text-primary-500 "
        >
          <h3>Tier: {index + 1}</h3>
          TierID: {tier.id} <br />
          Name: {tier.displayName} <br />
          Price: {tier.price} <br />
          {tier.image ? (
            <GatsbyImage
              image={tier.image.gatsbyImageData}
              alt={tier.image.altText}
            />
          ) : null}
        </div>
      ))}
      <br />
      <br />
    </div>
  );
};

export default Item;
